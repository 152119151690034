import React, { useEffect, useState } from "react";
import "./Lookbook.css";
import Logo from "../../Materials/Images/UnrealWearBlack.png";
import { ReactComponent as Arrow } from "../../Materials/Svg/arrow.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCollectionContent, getProductContent } from "../../Store/actions";
import { ReactComponent as NavArrow } from "../../Materials/Svg/navarrow.svg";

export default function Lookbook() {
  let Collection = useSelector((state) => state.lookbook.Collection);
  let Products = useSelector((state) => state.lookbook.Products);

  let [active, setActive] = useState(0);
  let [zoom, setZoom] = useState(false);
  let [sizeChart, setSizeChart] = useState(false);
  let [touchStart, setTouchStart] = useState(null);
  let [touchEnd, setTouchEnd] = useState(null);

  const minSwipeDistance = 50;

  const dispatch = useDispatch();

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      if (active + 1 !== Products.length) {
        setActive(active + 1);
      } else {
        setActive(0);
      }
    }
    if (isRightSwipe) {
      if (active + 1 !== 1) {
        setActive(active - 1);
      } else {
        setActive(Products.length - 1);
      }
    }
  };

  useEffect(() => {
    if (
      !Products ||
      Object.keys(Products).length <= 0 ||
      Products === undefined
    ) {
      dispatch(getCollectionContent()).then((c) => {
        dispatch(getProductContent(c.id));
      });
    }
  }, []);

  if (Products && Object.keys(Products).length > 0 && Products !== undefined) {
    return (
      <div className="Lookbook">
        <div className="Navbar">
          <Link to="/">
            <div className="NavArrow">
              <NavArrow />
            </div>
            <div className="Logo">
              <img src={Logo} alt="Unreal Wear" />
            </div>
          </Link>
        </div>
        <div className="Content">
          <div className="Top">
            <div className="Left"></div>
            <div className="Middle">
              <div
                className="Showitem"
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
              >
                <img
                  onClick={() => setZoom(true)}
                  src={Products[active].fields.image}
                  alt="showitem"
                />
              </div>
              <div className="Back buyNowMobile" onClick={() => window.open('https://www.instagram.com/unrealwear.store/')}>comprar</div>
            </div>
            <div className="Right">
              <div className="Items">
                {Products.sort((a, b) => a.fields.id > b.fields.id ? 1 : -1).map(
                  (item, i) => {
                    return (
                      <div
                        className={`Item ${active === i ? "Active" : ""}`}
                        onClick={() => setActive(i)}
                      >
                        <img src={item.fields.image} alt={item.fields.name} />
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
          <div className="Arrows">
            <Arrow
              onClick={() => {
                active + 1 !== 1
                  ? setActive(active - 1)
                  : setActive(Products.length - 1);
              }}
            />{" "}
            {active + 1} de {Products.length}{" "}
            <Arrow
              onClick={() => {
                active + 1 !== Products.length
                  ? setActive(active + 1)
                  : setActive(0);
              }}
            />
          </div>
          <div className="Bottom">
            <div className="Left">
              <div className="Logo">
                <Link to="/">
                  <img src={Logo} alt="Unreal Wear" />
                </Link>
              </div>
            </div>
            <div className="Middle">
              <div className="title">
                <strong>{Collection.name}</strong>
              </div>
              <div className="text">{Products[active].fields.name}</div>
              {/* <div className="text sizeBtn" onClick={() => setSizeChart(true)}>Size Chart</div> */}
            </div>
            <div className="Right Align-Right">
              {/* <div className="Back" onClick={() => window.open('https://www.instagram.com/unrealwear.store/')}>comprar</div> */}
              <Link to="/">
                <div className="Back">voltar</div>
              </Link>
            </div>
          </div>
          <a className="sizeChartArrow" onClick={() => setSizeChart(!sizeChart)}>Composição / Guia de tamanhos
           <br/>
            {sizeChart ?
              <label>&#9650;</label>
            : <label>&#9660;</label> }
          </a>
          {sizeChart && (
          <div className="sizeChartTable">
            Composição: 100% algodão 240grs<br></br><br></br>
            <table className="table">
              <tbody>
                <tr>
                  <td className="first-col">
                    <span>Tamanho</span>
                  </td>
                  <td><span className="align-center">S</span></td>
                  <td><span className="align-center">M</span></td>
                  <td><span className="align-center">L</span></td>
                </tr>
                <tr>
                  <td className="first-col">
                    <span className="cm">Altura (cm)</span>
                  </td>
                  <td>
                    <span className="cm align-center">74</span></td>
                  <td>
                    <span className="cm align-center">76</span></td>
                  <td>
                    <span className="cm align-center">78</span></td>
                </tr>
                <tr>
                  <td className="first-col">
                    <span className="cm align-center">Cintura (cm)</span>
                  </td>
                  <td>
                    <span className="cm align-center">57</span></td>
                  <td>
                    <span className="cm align-center">59</span></td>
                  <td>
                    <span className="cm align-center">61</span></td>
                </tr>
                <tr>
                  <td className="first-col">
                    <span className="cm">Largura Manga (cm)</span>
                  </td>
                  <td>
                    <span className="cm align-center">21,5</span></td>
                  <td>
                    <span className="cm align-center">23,5</span></td>
                  <td>
                    <span className="cm align-center">25,5</span></td>
                </tr>
                <tr>
                  <td className="first-col">
                    <span className="cm">Comprimento Manga (cm)</span>
                  </td>
                  <td>
                    <span className="cm align-center">25,5</span></td>
                  <td>
                    <span className="cm align-center">27,5</span></td>
                  <td>
                    <span className="cm align-center">29,5</span></td>
                </tr>
                </tbody>
            </table>
          </div>
          )}
        </div>
        {zoom && (
          <div className="Zoom">
            <img
              onClick={() => setZoom(false)}
              src={Products[active].fields.image}
              alt="showitem"
            />
          </div>
        )}
        {/* {sizeChart && (
          <div className="Zoom size">
            
            <table className="table">
            <div className="closeBtn" onClick={() => setSizeChart(false)}>close</div>
              <tbody>
                <tr>
                  <td className="first-col">
                    <span>Tamanho</span>
                  </td>
                  <td><span className="align-center">S</span></td>
                  <td><span className="align-center">M</span></td>
                  <td><span className="align-center">L</span></td>
                </tr>
                <tr>
                  <td className="first-col">
                    <span className="cm">Height/Altura (cm)</span><br></br>
                    <span className="in">Height/Altura (in)</span>
                  </td>
                  <td>
                    <span className="cm align-center">74</span><br></br>
                    <span className="in align-center">29,1</span></td>
                  <td>
                    <span className="cm align-center">76</span><br></br>
                    <span className="in align-center">29,9</span></td>
                  <td>
                    <span className="cm align-center">78</span><br></br>
                    <span className="in align-center">30,7</span></td>
                </tr>
                <tr>
                  <td className="first-col">
                    <span className="cm align-center">Waist/Cintura (cm)</span><br></br>
                    <span className="in align-center">Waist/Cintura (in)</span>
                  </td>
                  <td>
                    <span className="cm align-center">57</span><br></br>
                    <span className="in align-center">22,4</span></td>
                  <td>
                    <span className="cm align-center">59</span><br></br>
                    <span className="in align-center">23,2</span></td>
                  <td>
                    <span className="cm align-center">61</span><br></br>
                    <span className="in align-center">24</span></td>
                </tr>
                <tr>
                  <td className="first-col">
                    <span className="cm">Sleeve Width/Largura Manga (cm)</span><br></br>
                    <span className="in">Sleeve Width/Largura Manga (in)</span>
                  </td>
                  <td>
                    <span className="cm align-center">21,5</span><br></br>
                    <span className="in align-center">8,3</span></td>
                  <td>
                    <span className="cm align-center">23,5</span><br></br>
                    <span className="in align-center">9,2</span></td>
                  <td>
                    <span className="cm align-center">25,5</span><br></br>
                    <span className="in align-center">10</span></td>
                </tr>
                <tr>
                  <td className="first-col">
                    <span className="cm">Sleeve Length/Comprimento Manga (cm)</span><br></br>
                    <span className="in">Sleeve Length/Comprimento Manga (in)</span>
                  </td>
                  <td>
                    <span className="cm align-center">25,5</span><br></br>
                    <span className="in align-center">10</span></td>
                  <td>
                    <span className="cm align-center">27,5</span><br></br>
                    <span className="in align-center">10,8</span></td>
                  <td>
                    <span className="cm align-center">29,5</span><br></br>
                    <span className="in align-center">11,6</span></td>
                </tr>
                </tbody>
            </table>
          </div>
        )} */}
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
}
