import React, { useEffect, useState } from "react";
import "./About.css";
import Logo from "../../Materials/Images/UnrealWearBlack.png";
import { Link } from "react-router-dom";
import { ReactComponent as NavArrow } from "../../Materials/Svg/navarrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { getCollectionContent } from "../../Store/actions";

export default function About() {
  var [date, setDate] = useState(new Date());

  const dispatch = useDispatch();

  let Collection = useSelector((state) => state.lookbook.Collection);

  useEffect(() => {
    if (!Collection || !Collection.name || Collection.name === undefined) {
      dispatch(getCollectionContent());
    }
  }, []);

  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  return (
    <div className="About">
      <div className="Navbar">
        <Link to="/">
          <div className="NavArrow">
            <NavArrow />
          </div>
          <div className="Logo">
            <img src={Logo} alt="Unreal Wear" />
          </div>
        </Link>
      </div>
      <div className="Content">
        <div className="Logo">
          <Link to="/">
            <img src={Logo} alt="Unreal Wear" />
          </Link>
        </div>
        <div className="DateTime">
          {date.toLocaleDateString()}{" "}
          {date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}{" "}
          PT
        </div>
        <div className="Text">
          <p>
          A UNREAL WEAR, uma marca de roupa portuguesa moderna, foi fundada em 2023 com a intenção de fornecer artigos de moda de excelência que inspiram e dão poder às pessoas. Procuramos redefinir o negócio da moda com um amor pela inovação e uma dedicação inabalável à qualidade, produzindo designs distintos e de vanguarda que apelam aos nossos clientes.
          </p>
          <p>
          Acreditamos que o vestuário é mais do que apenas uma forma de nos mantermos cobertos. É uma declaração da personalidade, do sentido de estilo e do sentido de confiança de cada um. Graças à habilidade e criatividade dos nossos talentosos designers e artesãos, cada ponto de cada peça de vestuário é uma obra de arte. Do conceito à produção, seguimos um processo meticuloso para criar colecções que estão na moda e atraem a atenção.
          </p>
          <p>
          Orgulhamo-nos da nossa herança portuguesa e inspiramo-nos na habilidade artesanal e no rico passado cultural de Portugal.
          </p>
          <p>
          Entendemos que a moda é mais do que apenas roupa, é uma experiência. Prestamos um excelente serviço ao cliente para garantir que cada compromisso da UNREAL WEAR é tratado com profissionalismo, cuidado e atenção aos pormenores. Queremos construir relações duradouras com os nossos clientes com base na abertura, transparência e uma paixão partilhada pela moda.

          </p>
          <p>
          A UNREAL WEAR tem a missão de redefinir a moda e inspirar as pessoas a valorizarem a sua singularidade. Junte-se a nós nesta viagem emocionante, à medida que continuamos a derrubar barreiras, a questionar convenções e a criar vestuário que vai acima e além.
          </p>
        </div>
        <div className="Links">
          <ul>
            <li>
              <Link to="/">início</Link>
            </li>
            <li>
              <Link to="/lookbook">{Collection.name}</Link>
            </li>
            <li>
              <Link to="/about">sobre nós</Link>
            </li>
            {/* <li>
              <Link to="/stores">stores</Link>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}
