import { lookbookConstants } from "../constants";
import contentful from "../../Api/Contentful";
import { request, success, failure } from "../helpers/handlingFunctions";

export const getCollectionContent = () => async (dispatch) => {
  dispatch(request(lookbookConstants.GET_COLLECTION_CONTENT_REQUEST));
  return new Promise((resolve, reject) => {
    contentful
      .get(
        `/spaces/qkocx1z0gg8r/environments/master/entries?content_type=collection`
      )
      .then((response) => {
        let Collection = {
          id: response.data.items[0].sys.id,
          ...response.data.items[0].fields,
        };
        dispatch(
          success(lookbookConstants.GET_COLLECTION_CONTENT_SUCCESS, Collection)
        );
        resolve(Collection);
      })
      .catch((error) =>
        dispatch(
          failure(lookbookConstants.GET_COLLECTION_CONTENT_FAILURE, error)
        )
      );
  });
};

export const getProductContent = (Collection) => async (dispatch) => {
  dispatch(request(lookbookConstants.GET_PRODUCT_CONTENT_REQUEST));
  return new Promise((resolve, reject) => {
    contentful
      .get(
        `/spaces/qkocx1z0gg8r/environments/master/entries?select=fields&content_type=product&fields.collection.sys.id=${Collection}`
      )
      .then((response) => {
        dispatch(
          success(
            lookbookConstants.GET_PRODUCT_CONTENT_SUCCESS,
            response.data.items
          )
        );
        resolve(response.data.items);
      })
      .catch((error) =>
        dispatch(failure(lookbookConstants.GET_PRODUCT_CONTENT_FAILURE, error))
      );
  });
};
