const request = (type) => {
  return { type: type };
};
const success = (type, data) => {
  return { type: type, payload: data };
};
const failure = (type, error) => {
  return { type: type, payload: error };
};

export { request, success, failure };
