import React, { useEffect } from "react";
import "./Home.css";
import Logo from "../../Materials/Images/UnrealWear.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCollectionContent, getProductContent } from "../../Store/actions";

export default function Home() {
  const dispatch = useDispatch();

  let Collection = useSelector((state) => state.lookbook.Collection);

  useEffect(() => {
    if (!Collection || !Collection.name || Collection.name === undefined) {
      dispatch(getCollectionContent());
    }
  }, []);

  return (
    <div className="Home">
      <div className="Bk"></div>
      <div className="Content">
        <div className="Logo">
          <img src={Logo} alt="Unreal Wear" />
        </div>

        <div className="Links">
          <ul>
            <li>
              <Link to="/lookbook">{Collection.name}</Link>
            </li>
            <li>
              <Link to="/about">sobre nós</Link>
            </li>
            {/* <li>
              <Link to="/stores">stores</Link>
            </li> */}
          </ul>
          <div className="Social">
            <a
              href="https://www.instagram.com/unrealwear.store/"
              target="_blank"
            >
              <i className="fa-brands fa-instagram"></i>
            </a>
            <a href="https://www.tiktok.com/@unrealwear.store" target="_blank">
              <i className="fa-brands fa-tiktok"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
