import { storesConstants } from "../constants";

const initialState = {
  List: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case storesConstants.GET_STORES_CONTENT_REQUEST:
      return { ...state, ErrorGetStoresContent: null };
    case storesConstants.GET_STORES_CONTENT_SUCCESS:
      return { ...state, List: action.payload };
    case storesConstants.GET_STORES_CONTENT_FAILURE:
      return { ...state, ErrorGetStoresContent: action.error };

    default:
      return state;
  }
};
