import React, { useEffect } from 'react';
import "./Comingsoon.css";
import Story from "../../Materials/Videos/Story.mp4";


export default function Comingsoon() {

    useEffect(() =>{
        document.getElementById("STORY").play();
    }, [])

    return (
        <div className='Comingsoon'>
            <video id="STORY" width="480" height="848" autoPlay loop muted playsInline>
                <source src={Story} type="video/mp4" />
            </video>
        </div>
    )
}
