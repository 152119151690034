import { lookbookConstants } from "../constants";

const initialState = {
  Collection: {},
  Products: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case lookbookConstants.GET_COLLECTION_CONTENT_REQUEST:
      return { ...state, ErrorGetCollectionContent: null };
    case lookbookConstants.GET_COLLECTION_CONTENT_SUCCESS:
      return { ...state, Collection: action.payload };
    case lookbookConstants.GET_COLLECTION_CONTENT_FAILURE:
      return { ...state, ErrorGetCollectionContent: action.error };

    case lookbookConstants.GET_PRODUCT_CONTENT_REQUEST:
      return { ...state, ErrorGetProductContent: null };
    case lookbookConstants.GET_PRODUCT_CONTENT_SUCCESS:
      return {
        ...state,
        Products: action.payload,
      };
    case lookbookConstants.GET_PRODUCT_CONTENT_FAILURE:
      return { ...state, ErrorGetProductContent: action.error };

    default:
      return state;
  }
};
