import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Components/Home/Home";
// import Previews from "./Components/Previews/Previews";
import Lookbook from "./Components/Lookbook/Lookbook";
import About from "./Components/About/About";
import Stores from "./Components/Stores/Stores";
import Comingsoon from "./Components/Comingsoon/Comingsoon";

function App() {

  let currentDay = new Date();

  // Get the local time zone offset in minutes
  let timeZoneOffset = currentDay.getTimezoneOffset();

  let launchDay = new Date("2024-03-10T20:00:00Z");
  launchDay.setMinutes(launchDay.getMinutes() - timeZoneOffset);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {currentDay < launchDay ? (
            <Route path="/" element={<Comingsoon />} />
          ) : (
            <>
              <Route path="/" element={<Home />} /> 
              {/* <Route path="/previews" element={<Previews />} />  */}
              <Route path="/lookbook" element={<Lookbook />} /> 
              <Route path="/about" element={<About />} />
              {/* <Route path="/stores" element={<Stores />} /> */} 
            </>
          )}
          
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
